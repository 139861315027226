// REACT
import React from "react"
// GATSBY
import { graphql } from "gatsby"
// MUI
import { makeStyles } from "@material-ui/core/styles"
import { Link as MuiLink } from "@material-ui/core"
// COMPONENTS
import Layout from "../components/layout"
import { Link } from "../components/link"
import { rhythm, scale } from "../utils/typography"
// IMAGES
import PdfIcon from "../assets/pdf-icon.svg"

// STYLES
const useStyles = makeStyles(theme => ({
  pdf: {
    maxWidth: "64px",
    height: "auto",
    display: "block",
    margin: "2rem 0",
  },
}))

// COMPONENT FUNCTION
const BrokerageListingTemplate = ({ data, pageContext }) => {
  // STYLE OBJECT
  const classes = useStyles()

  const post = data.markdownRemark
  const { previous, next } = pageContext

  // COMPONENT
  return (
    <Layout
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    >
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(0.8),
              display: `block`,
              color: `#5db631`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.price}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.listingPdf ? (
          <MuiLink href={post.frontmatter.listingPdf}>
            <PdfIcon className={classes.pdf} />
          </MuiLink>
        ) : null}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BrokerageListingTemplate

export const pageQuery = graphql`
  query BrokerageListingBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        price
        description
        listingPdf
      }
    }
  }
`
